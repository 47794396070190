import angular from 'angular';
import app from '../../app';
import { getOffset } from '../../helpers';

app.controller('exportImportCERSCtrl', [
  '$scope',
  '$state',
  '$rootScope',
  '$window',
  '$timeout',
  '$http',
  'FileUploader',
  'SolrDocument',
  'SpinnerService',
  'Inventory',
  'InventoryService',
  'CompanyService',
  'ErrorService',
  'MessagesService',
  'ManufacturerService',
  'ModalService',
  exportImportCERSCtrl
]);

function exportImportCERSCtrl(
  $scope,
  $state,
  $rootScope,
  $window,
  $timeout,
  $http,
  FileUploader,
  SolrDocument,
  SpinnerService,
  Inventory,
  InventoryService,
  CompanyService,
  ErrorService,
  MessagesService,
  ManufacturerService,
  ModalService
) {
  $scope.exportType = $state.includes('tier2Report') ? 'tier2'
    : $state.includes('ReportTier2Texas') ? 'tier2texas' :  'cers';

  $scope.headerIndex = 3;
  $scope.headerIndex_cash = 3;
  $scope.content = '';
  $scope.step = null;
  $scope.dataIndex = { from: 4, to: 10000 };
  $scope.processResults = {};
  $scope.isInventoryModuleAvailable = InventoryService.isModuleAvailable(true);
  $scope.inventoryLoading = false;

  const tabIndexes = [1, 2, 3, 4];

  // init pages for each tab
  $scope.pager = {
    PER_PAGE: 30,
    startFrom: tabIndexes.reduce((all, el) => {
      all[el] = 0;
      return all;
    }, {}),
    tabOffset: tabIndexes.reduce((all, el) => {
      all[el] = 0;
      return all;
    }, {})
  };

  let inventoriesList = [];
  const MAX_INVENTORY_ITEMS = 1000;

  const simpleCatch = ErrorService.simpleCatch;
  ManufacturerService.manufacturersPromise()
    .then(function (manufacturers) {
      $scope.manufacturers = manufacturers;
    })
    .catch(simpleCatch);

  $scope.uploader = new FileUploader();

  /**
   * Autorun after controller initialization
   */
  const initController = function() {
    $scope.isInventoryModuleAvailable = InventoryService.isModuleAvailable(true);
    if ($scope.isInventoryModuleAvailable) {
      if (!$scope.inventoryLoading) {
        $scope.inventoryLoading = true;
        runInventoryCERSTest();
      }
    } else if ($rootScope.companySettings) {
      $scope.step = 0;
    }
  }

  /**
   * Show first step form
   */
  $scope.stepBack = function () {
    $scope.step = 0;
  }

  $scope.openDocument = function (id) {
    const url = '/viewSds/' + id;
    $window.open(url, '_blank');
  };

  $scope.onClickClass = function (iClass, data) {
    data[iClass] = data[iClass] === 'N' ? 'Y' : 'N';
  };

  $scope.handlePaginate = function (key) {
    $window.scrollTo(0, 0);
    $scope.pager.tabOffset[key] = getOffset($scope.pager.startFrom[key], $scope.pager.PER_PAGE);
  };

  $scope.onClickState = function (excelRow, state) {
    const index = excelRow.PhysicalState.indexOf(state);
    if (index > -1) {
      excelRow.PhysicalState.splice(index, 1);
    } else {
      excelRow.PhysicalState.push(state);
    }

    excelRow.getCell(getColIndex('PhysicalState')).value = excelRow.PhysicalState.join(';');
  };

  $scope.onClickStatus = function (excelRow, state, fieldName) {
    excelRow.getCell(getColIndex(fieldName)).value = excelRow[fieldName] = state;
  };

  $scope.uploader.onAfterAddingFile = function (arg) {
    const input = $('input[id=lefile]')['0'];
    const files = input.files;
    files['0'] = arg.file;
    const event = createNewEvent('change');
    event.droppedFile = arg._file;
    input.dispatchEvent(event);
  };

  $scope.onChangeSDSButtonClick = function (scope, excelRow, iDocument) {
    if (iDocument === undefined) {
      manuallySelect(scope, excelRow);
    } else {
      excelRow.selectedDocument = iDocument;
      excelRow.doNotProcessRow = false;
      $scope.updateCERSTest(excelRow);
    }
  };

  function createNewEvent(eventName) {
    var event;
    if (typeof Event === 'function') {
      event = new Event(eventName);
    } else {
      event = document.createEvent('Event');
      event.initEvent(eventName, true, true);
    }
    return event;
  }

  function downloadBlob(data, fileName, mimeType) {
    return new Promise((resolve, reject) => {
      let blob, url;
      blob = new Blob([data], {
        type: mimeType
      });
      url = window.URL.createObjectURL(blob);
      downloadURL(url, fileName);
      setTimeout(() => {
        resolve(window.URL.revokeObjectURL(url));
      }, 1000);
    });
  }

  function downloadURL(data, fileName) {
    const a = document.createElement('a');
    a.href = data;
    a.download = fileName;
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.remove();
  }
  /**
   * Copy data from one Excel sheet to another
   */
  function copyExcelSheetData(sheetFrom, sheetTo) {
    for (let iRow = 0; iRow < sheetFrom.rowCount; iRow++) {
      const copyFromRow = sheetFrom.getRow(iRow + 1);
      const copyToRow = sheetTo.getRow(iRow + 1);
      if (rowIsEmpty(copyFromRow)) continue;
      if (isDataRow(iRow)) {
        for (let iCol = 1; iCol <= $scope.arrNames.length; iCol++) {
          copyToRow.getCell(iCol).value = copyFromRow.getCell(iCol).value;
        }
      }
    }
  }

  async function readData(content = null) {
    const Excel = ExcelJS;
    const workbook = new Excel.Workbook();
    const isInventorySheet = sheet => sheet.name.trim().toLowerCase() === 'inventory';

    //get report base blank xls document
    $scope.content = await readBlankFile();

    let book = await workbook.xlsx.load($scope.content.result);
    book.inventory = book.worksheets.find(isInventorySheet);

    if (content) {
      let bookToCopy = await (new Excel.Workbook()).xlsx
        .load(content.result)
        .catch(function () {
          const err = new Error('Load file error: incorrect format');
          ErrorService.simpleCatch(err);
        });
      bookToCopy.inventory = bookToCopy.worksheets.find(isInventorySheet);
      copyExcelSheetData(bookToCopy.inventory, book.inventory);
    }
    $scope.excel = book;
    return $scope.processExcel();
  }

  /**
   * Read blank CERS template without formulas and filters
   * It speeds up buffer load performance
   */
  async function readBlankFile() {
    let r = null;
    await $http.get('/content/CERS/CERSBlank.xlsx', {responseType: 'arraybuffer'}).success((data) => {
      r = {result: data, name: 'Report.xlsx'};
    });
    return r;
  }

  /**
   * Run data test based on inventory module
   */
  const runInventoryCERSTest = SpinnerService.wrap(function () {
    $scope.processResults = {};
    return readData();
  });

  /**
   * Fill inventory data
   */
  function fillInventoryData() {
    let companyInventories = {};
    let options = {
      inventorySearch: true,
      filter: {
        where: { companyId: $rootScope.companyId},
        exportType: $scope.exportType
      }
    };

    return CERSTest(options);
  }

  /**
   * Get Inventory products list
   * @param {Object} inventory Inventory information
   * @param {String} parent Parent inventory name
   */
  function sortInventories(inventory, parent = '') {
    let sorted = [];
    let path = parent ? parent + '/' + inventory.name : inventory.name;
    (inventory.products || []).forEach(product => {
      if (product.sdsId.length) {
        sorted.push({
          'inventory': path,
          'sdsId': product.sdsId[0],
          'product': product
        });
      }
    });
    (inventory.inventories || []).forEach(inventoryEl => {
      let res = sortInventories(inventoryEl, path);
      sorted = sorted.concat(res);
    });

    return sorted;
  }

  /**
   * Add inventories SDS data to test results and sheet
   * @param {Array} docs SDS docs
   * @param {Object} options
   */
  function addInventoriesSDS(docs, options) {

    //preload indexes values
    let indexes = {};
    ['MaximumDailyAmount', 'ChemicalLocation', 'ChemicalName', 'CASNumber', 'CommonName', 'TradeSecret', 'EHS', 'PhysicalState', 'OtherStorageContainer', 'Workplaces'].forEach( el => {
      indexes[el] = getColIndex(el);
    });

    if (docs.length >= MAX_INVENTORY_ITEMS) {
      MessagesService.warning(
        'REPORTS.CERS.CERS_LIMIT_WARN',
        {
          limit: MAX_INVENTORY_ITEMS
        },
        {
          closeButton: true,
          timeOut: 0,
          extendedTimeOut: 0
        }
      );
    }
    if (options.inventorySearch) {
      for (let iRow = 0; iRow < docs.length; iRow++) {
        let el = $scope.excel.inventory.getRow(iRow + 4);
        el.doNotProcessRow = false;

        const pathArr = docs[iRow].inventory.path.split('/');
        el.getCell(indexes.ChemicalLocation).value = pathArr[0] || '';
        el.getCell(indexes.Workplaces).value = (docs[iRow].inventory.included || []).join(".");
        el.getCell(indexes.CommonName).value = docs[iRow].product.name;

        if (docs[iRow].amounts) {
          el.getCell(indexes.MaximumDailyAmount).value = docs[iRow].amounts.join('; ');
        }

        el.getCell(indexes.PhysicalState).value = docs[iRow].state || '';
        if (docs[iRow].SDS) {
          el.selectedDocument = 0;
          el.document = [docs[iRow].SDS];
          el.disabledSDSSelect = true;
          el.getCell(indexes.ChemicalName).value = docs[iRow].SDS.chemicalFriendlyName;
          el.getCell(indexes.CASNumber).value = docs[iRow].cas || '';
          el.getCell(indexes.TradeSecret).value = docs[iRow].classes['TradeSecret'] || 'N';
          el.getCell(indexes.EHS).value = (docs[iRow].EHS || []).length > 0 ? 'Y' : 'N';
          if (docs[iRow].product.containerType) {
            el.getCell(indexes.OtherStorageContainer).value = docs[iRow].product.containerType;
          }

          addResult(4 /* add to success list */, el);
          el.doNotProcessRow = false;
        } else {
          el.document = [];
          el.getCell(indexes.ChemicalName).value = docs[iRow].product.name;
          addResult(2 /* add to success list */, el);
        }
      }
    }
  }

  $scope.$on('onLoadCERSData', function (listener, content) {
    SpinnerService.wrap(async function () {
       await readData(content);
    })();
  });

  $scope.arrNames = [];
  {
    const name1 =
      '205	253 1a*	201	202	203	204	206	207*	208	209	210a	210b	210c	210d	210e	210f	210g	210h	211	212	213	214*	215	216a	216b	216c	216d	216e	216f	216g	216h	216i	216j	216k	216l	216m	216n	216o	216p	216q	216r	216s	216t	216u	216v	216w	216x	216y	216z	216aa	216bb	216cc	217	218*	219	220	221*	222	223a	223b	223c	223d	223e	223f	223g	223h	223i	223j	223k	223l	223m	223n	223o	223p	223q	223r	223r-1	224	225	226	227	228	229	230	231	232	233	234	235	236	237	238	239	240	241	242	243	244	245	246	247	250	251	252	253	254';
    const name2 =
      'ChemicalName AssetTemplateID CERSID	ChemicalLocation	CLConfidential	MapNumber	GridNumber	TradeSecret	CommonName	EHS	CASNumber	PFCodeHazardClass	SFCodeHazardClass	TFCodeHazardClass	FFCodeHazardClass	FifthFireCodeHazardClass	SixthFireCodeHazardClass	SeventhFireCodeHazardClass	EighthFireCodeHazardClass	HMType	RadioActive	Curies	PhysicalState	LargestContainer	FHCFire	FHCReactive	FHCPressureRelease	FHCAcuteHealth	FHCChronicHealth	FHCPhysicalFlammable	FHCPhysicalGasUnderPressure	FHCPhysicalExplosive	FHCPhysicalSelfHeating	FHCPhysicalPyrophoric	FHCPhysicalOxidizer	FHCPhysicalOrganicPeroxide	FHCPhysicalSelfReactive	FHCPhysicalPyrophoricGas	FHCPhysicalCorrosiveToMetal	FHCPhysicalContactWaterEmitsFlammableGas	FHCPhysicalCombustibleDust	FHCPhysicalHazardNotOtherwiseClassified	FHCHealthCarcinogenicity	FHCHealthAcuteToxicity	FHCHealthReproductiveToxicity	FHCHealthSkinCorrosionIrritation	FHCHealthRespiratorySkinSensitization	FHCHealthSeriousEyeDamageEyeIrritation	FHCHealthSpecificTargetOrganToxicity	FHCHealthAspirationHazard	FHCHealthGermCellMutagenicity	FHCHealthSimpleAsphyxiant	FHCHealthHazardNotOtherwiseClassified	AverageDailyAmount	MaximumDailyAmount	AnnualWasteAmount	StateWasteCode	Units	DaysOnSite	SCAboveGroundTank	SCUnderGroundTank	SCTankInsideBuilding	SCSteelDrum	SCPlasticNonMetallicDrum	SCCan	SCCarboy	SCSilo	SCFiberDrum	SCBag	SCBox	SCCylinder	SCGlassBottle	SCPlasticBottle	SCToteBin	SCTankTruckTankWagon	SCTankCarRailCar	SCOther	OtherStorageContainer	StoragePressure	StorageTemperature	HC1PercentByWeight	HC1Name	HC1EHS	HC1CAS	HC2PercentByWeight	HC2Name	HC2EHS	HC2CAS	HC3PercentByWeight	HC3Name	HC3EHS	HC3CAS	HC4PercentByWeight	HC4Name	HC4EHS	HC4CAS	HC5PercentByWeight	HC5Name	HC5EHS	HC5CAS	ChemicalDescriptionComment	AdditionalMixtureComponents	CCLID	USEPASRSNumber	DOTHazardClassificationID	AssetTemplateID	Workplaces';

    const arr1 = name1.split(/\s+/);
    const arr2 = name2.split(/\s+/);

    for (let i = 0; i < arr1.length; i++) {
      $scope.arrNames.push([arr1[i], arr2[i]]);
    }
  }

  function getColIndex(name, indexStrategy) {
    let columnName = -1;
    indexStrategy = indexStrategy || $scope.excel.inventory.columnStrategyIndex;
    for (let index = 0; index < $scope.arrNames.length; index++) {
      const element = $scope.arrNames[index];
      if (element[indexStrategy].toLowerCase() === name.toLowerCase()) {
        columnName = element[indexStrategy];
        break;
      }
    }
    return $scope.excel.inventory.header.values.indexOf(columnName);
  }

  function getCellValue(el, name, indexStrategy) {
    indexStrategy = indexStrategy | 1;
    let index = getColIndex(name, indexStrategy);
    let value = el.values && el.values[index] ? el.values[index] : '';
    return value;
  }

  function markFireCodeClass(excelRow, fccCode, className, mark) {
    if (excelRow.fireCodeHazardClasses[fccCode]) {
      excelRow.fireCodeHazardClasses[fccCode] = mark;
    }

    if (excelRow.classes[className]) {
      excelRow.classes[className] = mark;
    }
  }

  $scope.processSpecificClasses = function (excelRow) {
    let value = 'N';
    excelRow.fireCodeHazardClasses = excelRow.fireCodeHazardClasses || [];
    if (excelRow.physicalHazard) {
      value = 'Y';
    }
    markFireCodeClass(
      excelRow,
      39,
      'FHCPhysicalHazardNotOtherwiseClassified',
      value
    );
    // excelRow[getColumnIndex('FHCHealthHazardNotOtherwiseClassified')] = value;
    excelRow.getCell(getColIndex('FHCPhysicalHazardNotOtherwiseClassified')).value = value;
    value = 'N';
    if (excelRow.healthHazard) {
      value = 'Y';
    }
    markFireCodeClass(excelRow, 27, 'FHCHealthHazardNotOtherwiseClassified', value);
    excelRow.getCell(getColIndex('FHCHealthHazardNotOtherwiseClassified')).value = value;
  };

  function additionalData(excel) {
    const ret = { additions: [] };

    for (let iRow = 0; iRow < excel.inventory.rowCount; iRow++) {
      const element = excel.inventory.getRow(iRow + 1);
      const newRow = {};
      newRow.fireCodeHazardClasses = element.fireCodeHazardClasses;
      newRow.row = element.row;
      newRow.cas = element.cas;
      newRow.classes = element.classes;
      newRow.doNotProcessRow = element.doNotProcessRow;
      newRow.document = element.document;
      newRow.selectedDocument = element.selectedDocument;
      newRow.initialCondition = element.initialCondition;
      ret.additions.push(newRow);
    }

    return ret;
  }

  $scope.saveData = SpinnerService.wrap(function () {
    return $scope.excel.xlsx.writeBuffer().then(function (buffer) {
      const additions = additionalData($scope.excel);
      return SolrDocument.beforeGenerateCERS({ excel: buffer, additionalData: additions, companyId: $rootScope.companyId, byInventory: $scope.isInventoryModuleAvailable, exportType: $scope.exportType }).$promise.then(
        function (dataToDownload) {
          return downloadBlob(
            new Uint8Array(dataToDownload),
            $scope.content.name || 'Report.xlsx',
            'application/octet-stream'
          ).then(() => {
            return true;
          });

        }
      );
    });
  });

  function isDataRow(index) {
    return index >= $scope.dataIndex.from - 1 && index <  $scope.dataIndex.to;
  }

  function rowIsEmpty(row) {
    let isEmpty = true;
    for (let index = 0; index < row.cellCount; index++) {
      const cell = row.getCell(index + 1);
      if (cell.value !== null) {
        isEmpty = false;
        break;
      }
    }

    return isEmpty;
  }

  function setAskPhysicalHealthHazard(columnName, docClass, excelRow) {
    if (columnName === 'FHCPhysicalHazardNotOtherwiseClassified') {
      if (docClass[columnName] === 'Y') {
        excelRow.showPhysicalHazard = true;
      } else if (excelRow.additionalClasses['HNOC'] && excelRow.additionalClasses['HNOC'] === 'Y') {
        excelRow.askPhysicalHazard = true;
        excelRow.askWordPhysicalHazard = excelRow.additionalClasses['HNOC'];
      }
    }

    if (columnName === 'FHCHealthHazardNotOtherwiseClassified') {
      if (docClass[columnName] === 'Y') {
        excelRow.showHealthHazard = true;
      }  else if (excelRow.additionalClasses['HNOC'] && excelRow.additionalClasses['HNOC'] === 'Y') {
        excelRow.askHealthHazardy = true;
        excelRow.askWordHealthHazard = excelRow.additionalClasses['HNOC'];
      }
    }
  }

  function CERSTest(options) {
    function initialState(el) {
      el.initialCondition = {
        fireCodeHazardClasses: angular.copy(el.fireCodeHazardClasses),
        classes: angular.copy(el.classes),
        cas: angular.copy(el.cas),
        doNotProcessRow: el.initialCondition
          ? el.initialCondition.doNotProcessRow
          : el.doNotProcessRow,
        document: el.initialCondition ? el.initialCondition.document : angular.copy(el.document),
        selectedDocument: el.initialCondition
          ? el.initialCondition.selectedDocument
          : el.selectedDocument
      };
    }
    let CERSPromise = $scope.isInventoryModuleAvailable && options.inventorySearch ? Inventory.getInventoryCERS(options): SolrDocument.CERS(options);

    return CERSPromise.$promise.then(function (resp) {
      const docs = resp;
      const iAssetTemplateID = getColIndex('AssetTemplateID');

      //add inventory products to sheet
      if ($scope.isInventoryModuleAvailable) {
        addInventoriesSDS(docs, options);
      }

      let emptyRows = 0; //Sometimes crashed, if we have so many empty rows
      for (let iRow = 0; iRow < $scope.excel.inventory.rowCount; iRow++) {
        const el = $scope.excel.inventory.getRow(iRow + 1);
        if (rowIsEmpty(el)) continue;
        el[iAssetTemplateID] = undefined;
        if (!isDataRow(iRow)) continue;
        if (el.selectedDocument === undefined) {
          initialState(el);
          continue;
        }

        for (let iDocRow = 0; iDocRow < docs.length; iDocRow++) {
          const selectedDocument = el.document[el.selectedDocument];
          if (docs[iDocRow].SDS
            && docs[iDocRow].SDS.id === selectedDocument.id) {
            const docRow = docs[iDocRow];
            el.fireCodeHazardClasses = Object.keys(docRow.fireCodeHazardClasses).sort().reduce(
              (obj, key) => {
                obj[key] = docRow.fireCodeHazardClasses[key];
                return obj;
              },
              {}
            );
            el.cas = docRow.cas;
            el.additionalClasses = docRow.additionalClasses;
            el.classes = Object.keys(docRow.classes).sort().reduce(
              (obj, key) => {
                obj[key] = docRow.classes[key];
                return obj;
              },
              {}
            );
            for (let iClass in docRow.classes) {
              setAskPhysicalHealthHazard(iClass, docRow.classes, el);
              el.getCell(getColIndex(iClass)).value = docRow.classes[iClass];
            }
            $scope.processSpecificClasses(el);
            break;
          }
        }

        initialState(el);
      }
      $scope.step = 1;
      $scope.inventoryLoading = false;
      return true;
    });
  }

  function addResult(category, result) {
    if (typeof result === 'object') {
      let resultIsEmpty = true;
      for (let i = 0; i < result.cellCount; i++) {
        const cell = result.getCell(i + 1);
        if (cell && cell.value && cell.value.trim()) {
          resultIsEmpty = false;
          break;
        }
      }

      if (resultIsEmpty) return result;
    }
    let categoryName;
    let categoryNameTranslationKey;
    switch (category) {
      case 1:
        categoryName = 'Missing columns';
        categoryNameTranslationKey = 'REPORTS.CERS.MISSING_COLUMNS';
        break;
      case 2:
        categoryName = 'No SDS Found';
        categoryNameTranslationKey = 'REPORTS.CERS.NO_SDS_FOUND';
        break;
      case 3:
        categoryName = 'Multiple SDSs Found';
        categoryNameTranslationKey = 'REPORTS.CERS.MULTIPLE_SDS_FOUND';
        break;
      case 4:
        categoryName = 'Processed';
        categoryNameTranslationKey = 'REPORTS.CERS.PROCESSED';
        break;
      default:
        categoryName = 'Common message';
        categoryNameTranslationKey = 'REPORTS.CERS.COMMON_MESSAGE';
        break;
    }

    if (typeof $scope.processResults[category] !== 'object')
      $scope.processResults[category] = {
        categoryName,
        excelRow: [],
        collapsed: false,
        categoryNameTranslationKey
      };

    const ret = typeof result === 'string' ? {} : result;
    ret.name = typeof result === 'string' ? result : getCellValue(result, 'ChemicalName');
    ret.PhysicalState = (getCellValue(result, 'PhysicalState') || '').split(';');
    ret.EHS = getCellValue(result, 'EHS') || '';
    ret.doNotProcessRow = true;

    $scope.processResults[category].excelRow.push(ret);
    return ret;
  }

  const mappingExcel = SpinnerService.wrap(function () {
    const fields =
      'chemicalFriendlyName, chemicalFriendlyName_string_ci, id, hazards_identification, product_and_company_identification, manufacturer, ';
    // prepared data

    let assetTemplateID = [];
    let names = [];
    let emptyRows = 0; //Sometimes crashed, if we have so many empty rows
    for (let iRow = 0; iRow < $scope.excel.inventory.rowCount; iRow++) {
      const el = $scope.excel.inventory.getRow(iRow + 1);
      if (rowIsEmpty(el)) continue;
      if (isDataRow(iRow)) {
        el.row = iRow + 1;
        const id = getCellValue(el, 'AssetTemplateID');
        if (id) assetTemplateID.push(id.trim());
        el['AssetTemplateID_temporary'] = id ? id.trim() : undefined;

        let name = getCellValue(el, 'ChemicalName');
        if (name) {
          names.push({ name: name, row: iRow });
        }
        el['chemicalName_temporary'] = name;
      }
    }

    const attr = 'attr_' + $rootScope.companyId;
    assetTemplateID = assetTemplateID.map(function (el) {
      return attr + ': "AssetTemplateID=' + el + '"';
    });

    // if the filter is empty, the query should return empty results.
    assetTemplateID.push('AssetTemplateID=false');

    const query = {
      filter: [
        assetTemplateID.join(' OR '),
        'companyId: ' + $rootScope.companyId,
        'language: "en"',
        '-isArchived: true',
        '-isVerified: false'
      ],
      fields: fields + attr
    };

    // Trying to search by AssetTemplateID;
    return SolrDocument.filterDocuments(query).$promise.then(function (results) {
      const docs = results.response.docs;
      let emptyRows = 0; //Sometimes crashed, if we have so many empty rows
      for (let iRow = 0; iRow < $scope.excel.inventory.rowCount; iRow++) {
        const el = $scope.excel.inventory.getRow(iRow + 1);
        if (rowIsEmpty(el)) continue;
        if (!isDataRow(iRow)) continue;

        for (let docRow = 0; docRow < docs.length; docRow++) {
          const arrDocs = docs[docRow][attr].filter(function (attr) {
            return attr === 'AssetTemplateID=' + el.AssetTemplateID_temporary;
          });
          if (arrDocs.length > 0) {
            if (!Array.isArray(el.documentAT)) el.documentAT = [];
            el.documentAT.push(docs[docRow]);
          }
        }
      }

      // if the filter is empty, the query should return empty results.
      // names.push('chemicalFriendlyName: false');

      const query = {
        names: names,
        filter: [
          'companyId: ' + $rootScope.companyId,
          'language: "en"',
          '-isArchived: true',
          '-isVerified: false'
        ],
        fields: fields
      };

      // Trying to search by chemical name;
      return SolrDocument.mappedDocumentsToQuery(query).$promise.then(function (results) {
        for (let iRow = 0; iRow < results.length; iRow++) {
          const el = results[iRow];
          const excelRow = $scope.excel.inventory.getRow(el.query.row + 1);
          excelRow.documentCFN = el.docs;
        }

        let emptyRows = 0; //Sometimes crashed, if we have so many empty rows
        for (let iRow = 0; iRow < $scope.excel.inventory.rowCount; iRow++) {
          const el = $scope.excel.inventory.getRow(iRow + 1);
          if (rowIsEmpty(el)) continue;
          if (!isDataRow(iRow)) continue;
          const arr1 = el.documentAT || [];
          const arr2 = el.documentCFN || [];
          el.document = arr1.concat(arr2);
          el.documentAT = undefined;
          el.documentCFN = undefined;

          if (el.document.length === 0) {
            addResult(2, el);
          } else if (el.document.length > 1) {
            addResult(3, el);
          } else if (el.document.length === 1) {
            addResult(4, el);
            el.selectedDocument = 0;
            el.doNotProcessRow = false;
          }
        }

        const ids = !$scope.processResults[4]
          ? []
          : $scope.processResults[4].excelRow.map(function (el) {
            return {
              id: el.document[0].id,
              additionalData: { columnV: getCellValue(el, 'PhysicalState') }
            };
          });

        const options = {
          ids: ids,
          start: 0,
          rows: 2147483647
        };
        return CERSTest(options);
      });
    });
  });

  function manuallySelect(scope, excelRow) {
    // excelRow.selectedDocument = undefined;
    const simpleCatch = ErrorService.simpleCatch;

    const childScope = scope.$new();

    ModalService.open({
      templateUrl: '/views/modals/cers-select-sds-manually.html',
      scope: childScope,
      controller: [
        '$scope',
        '$uibModalInstance',
        '$translate',
        'ManufacturerService',
        'SearchPageService',
        function ($scope, $uibModalInstance, $translate, ManufacturerService, SearchPageService) {
          const modal = this;
          $scope.label = 'COMMON.LABELS.SEARCH_SDSS';
          $scope.isLoading = null;
          ManufacturerService.manufacturersPromise()
            .then(initManufacturersDropdown)
            .catch(simpleCatch);

          $scope.searchSDSToLink = function (text) {
            if (!text && $scope.searchByManufacturer === '*') return;

            $scope.isLoading = true;

            const query = {
              query: text,
              filters: [
                'companyId: ' + $rootScope.companyId,
                'language: "en"',
                '-isArchived: true',
                '-isArchived: true'
              ],
              limit: 999999,
              offset: 0
            };

            if ($scope.searchByManufacturer !== '*') {
              query.filters.push('manufacturer:' + $scope.searchByManufacturer);
            }

            return SolrDocument.findSds(query).$promise.then(function (response) {
              $scope.sdsToLink = response.docs;
              $scope.isLoading = false;
            });
          };

          $scope.handleAdd = function (sds) {
            const docs = $scope.excelRow.document;
            const i = docs.push(sds) - 1;

            $timeout(
              function (i) {
                $scope.excelRow.selectedDocument = i;
                $scope.$close($scope.excelRow);
              },
              0,
              true,
              i
            );
          };

          function initManufacturersDropdown(manufacturers) {
            $translate('SEARCH.SEARCH_ALL').then(function (translation) {
              $scope.manufacturers = manufacturers;
              $scope.manufacturersToSearch = angular.extend(
                { '*': { id: '*', name: translation } },
                manufacturers
              );
              $scope.searchByManufacturer = $scope.manufacturersToSearch['*'].id;
            });
          }

          const customFilters = {
            filters: [{ field: '-kit_ids', value: '[\'\' TO *]' }]
          };

          modal.selectedSds = {};
          modal.sps = SearchPageService;

          modal.sps.onReset();

          modal.onSearch = function (opts) {
            const payload = angular.copy(customFilters, opts || {});

            modal.sps.doSearch(payload);
          };

          modal.onPageChange = function () {
            modal.sps.onPageChange(customFilters);
          };

          modal.cancel = $uibModalInstance.dismiss;
        }
      ]
    }).then(function (res) {
      if (res.selectedDocument !== undefined) res.doNotProcessRow = false;
      $scope.updateCERSTest(res);
    });
  }

  $scope.safeApply = function (fn) {
    const phase = this.$root.$$phase;
    if (phase == '$apply' || phase == '$digest') {
      if (fn && typeof fn === 'function') {
        fn();
      }
    } else {
      this.$apply(fn);
    }
  };

  $scope.updateCERSTest = SpinnerService.wrap(function (excelRow) {
    const ids = [
      {
        id: excelRow.document[excelRow.selectedDocument].id,
        additionalData: { columnV: getCellValue(excelRow, 'PhysicalState') }
      }
    ];

    const options = {
      ids: ids,
      start: 0,
      rows: 2147483647
    };
    return CERSTest(options);
  });

  $scope.onSelectedSDSChange = function (scope, excelRow) {
    if (excelRow.selectedDocument === undefined) manuallySelect(scope, excelRow);
    else $scope.updateCERSTest(excelRow);
  };

  $scope.processExcel = function () {
    $scope.processResults = {};
    $scope.excel.inventory.header = angular.copy($scope.excel.inventory.getRow($scope.headerIndex));

    $scope.excel.inventory.columnStrategyIndex = -1;
    if ($scope.excel.inventory.header.values.indexOf($scope.arrNames[0][0]) > 0)
      $scope.excel.inventory.columnStrategyIndex = 0;
    else if ($scope.excel.inventory.header.values.indexOf($scope.arrNames[0][1]) > 0)
      $scope.excel.inventory.columnStrategyIndex = 1;

    if ($scope.excel.inventory.columnStrategyIndex < 0) {
      MessagesService.error('Incorrect Excel format. Process was broken');
      return;
    }

    for (let key in $scope.arrNames) {
      const element = $scope.arrNames[key];
      if (
        $scope.excel.inventory.header.values.indexOf(
          element[$scope.excel.inventory.columnStrategyIndex]
        ) < 0
      ) {
        addResult(1, element[$scope.excel.inventory.columnStrategyIndex]);
      }
    }

    if (!$scope.isInventoryModuleAvailable) {
      mappingExcel();
    } else {
      return fillInventoryData();
    }

    return;
  };

  $scope.isEmptyProcessResults = function () {
    return Object.keys($scope.processResults).length === 0;
  };

  /**
   * Check if export type is Tier 2 report
   *
   * @returns {boolean}
   */
  function isTier2() {
      retrun ['tier2', 'tier2texas'].includes($scope.exportType);
  }

  $scope.$watch('companySettings', function () {
    initController();
  });
}
