import angular from 'angular';
import app from '../app';
import { waitForProp, getOffset as getOffsetHelper } from '../helpers';

app.service('SdsRequestService', [
  '$rootScope',
  '$translate',
  '$http',
  'LoopBackResource',
  'SdsRequest',
  'CompanyService',
  'SpinnerService',
  'ErrorService',
  function (
    $rootScope,
    $translate,
    $http,
    LoopBackResource,
    SdsRequest,
    CompanyService,
    SpinnerService,
    ErrorService
  ) {
    const statusCodes = {
      OPEN: 0,
      APPROVED: 1,
      DENIED: 2,
      COMPLETED: 3
    };
    const simpleCatch = ErrorService.simpleCatch;
    const self = this;

    let companyId;
    let orderType = 'date';
    let order = ' DESC';
    let dateFrom = null;
    let dateTo = null;
    let statusesToFilter = [];

    this.showApprovedOnly = false;
    this.showOpenOnly = false;
    this.rangePickerApi = {};
    this.PER_PAGE = 20;
    this.startFrom = 1;
    this.totalItems = 0;
    this.companies = [];
    this.orders = {
      date: true
    };

    const waitForGetEvalDate = waitForProp(this.rangePickerApi, 'getEvalDate');

    this.getStatusTranslation = function (statusCode) {
      return $translate.instant('REPORTS.SDS_REQUESTS.STATUSES.' + statusCode);
    };

    this.getStatusCode = function (statusName) {
      return statusCodes[
        Object.keys(statusCodes).filter(function (status) {
          return status.toLowerCase() === statusName.toLowerCase();
        })[0]
      ];
    };

    this.statusChecker = function (request) {
      return function (statuses) {
        statuses = Array.isArray(statuses) ? statuses : [statuses];

        return statuses.some(function (status) {
          return request.status === self.getStatusCode(status);
        });
      };
    };

    this.getDownloadLink = function (filename) {
      return '/api/containers/request/download/' + encodeURIComponent(filename);
    };

    this.toggleFilterByStatus = function (status) {
      const statusCode = typeof status === 'number' ? status : this.getStatusCode(status);

      if (!~statusesToFilter.indexOf(statusCode)) {
        statusesToFilter = statusesToFilter.concat(statusCode);
      } else {
        statusesToFilter = statusesToFilter.filter(function (status) {
          return status !== statusCode;
        });
      }

      this.getRecords(getOffset(), orderType + order);
      createPagination();
    };

    this.getOnlyApprovedRequests = function () {
      this.toggleFilterByStatus('approved');
    };

    this.getOnlyOpenRequests = function () {
      this.toggleFilterByStatus('open');
    };

    this.handlePaginate = function () {
      self.getRecords(getOffset(), orderType + order);
    };

    this.onCompanySelected = function (company) {
      companyId = company.id;
      self.startFrom = 1;

      return waitForGetEvalDate().then(() => self.changeDate(self.rangePickerApi.getEvalDate()));
    };

    this.reverseOrder = function (type) {
      this.orders[type] = !this.orders[type];
      orderType = type;
      order = this.orders[type] ? ' DESC' : ' ASC';

      this.getRecords(getOffset(), type + order);
    };

    this.getRecords = SpinnerService.wrap(function (offset, order) {
      return getRecords({
        limit: self.PER_PAGE,
        offset,
        order: order,
        companyId: companyId,
        statuses: statusesToFilter,
        from: dateFrom,
        to: dateTo
      }).then(function (records) {
        self.records = records.map(function (record) {
          record = record.toJSON();
          record.note = record.history[0] && record.history[0].note;

          return record;
        });

        return records;
      });
    });

    this.getRecords2Export = SpinnerService.wrap(function () {
      return getRecords({
        limit: 0,
        offset: 0,
        order: 'date DESC',
        companyId: companyId,
        statuses: statusesToFilter,
        from: dateFrom,
        to: dateTo
      }).then(function (records) {
        return records;
      });
    });

    this.changeDate = function (date) {
      self.startFrom = 1;
      dateFrom = date.start;
      dateTo = date.end;

      createPagination();
      return self.getRecords(0);
    };

    this.getHistory = SpinnerService.wrap(function (id) {
      return SdsRequest.prototype$__get__history({ id: id }).$promise.catch(simpleCatch);
    });

    this.sendRequest = SpinnerService.wrap(function (
      formObj,
      { existingSdsId, tagIds = [], inventoryIds = [] } = {}
    ) {

      const params = {
        companyId: $rootScope.companyId,
        userId: $rootScope.currentUser && $rootScope.currentUser.id,
        existingSdsId
      };
      let fd = null;

      if (formObj instanceof HTMLFormElement) {
        fd = new FormData(formObj);
      } else {
        fd = new FormData();

        Object.keys(formObj).forEach(key => {
          fd.append(key, formObj[key]);
        });
      }

      fd.append('tagIds', tagIds);
      fd.append('inventoryIds', inventoryIds);

      return $http({
        method: 'POST',
        url: LoopBackResource.getUrlBase() + '/SdsRequests/requestSds',
        params,
        data: fd,
        // assign content-type as undefined, the browser
        // will assign the correct boundary for us
        headers: { 'Content-Type': undefined },
        // prevents serializing payload
        transformRequest: angular.identity
      })
        .then(() => ({ ...params, data: fd }))
        .catch(simpleCatch);
    });

    this.deny = SpinnerService.wrap(function (requestId, companyId, note) {
      return SdsRequest.deny(
        { requestId: requestId, companyId: companyId },
        { note: note }
      ).$promise.catch(simpleCatch);
    });

    this.approve = SpinnerService.wrap(function (
      requestId,
      companyId,
      tagIds,
      inventoryIds = [],
      approverForm = {}
    ) {

      return SdsRequest.approve(
        { requestId: requestId },
        { companyId: companyId, tagIds: tagIds, inventoryIds, approverForm }
      ).$promise.catch(simpleCatch);
    });

    this.complete = SpinnerService.wrap(function (requestId, companyId) {
      return SdsRequest.complete(
        { requestId: requestId, companyId: companyId },
        null
      ).$promise.catch(simpleCatch);
    });

    this.init = SpinnerService.wrap(async function () {
      await waitForGetEvalDate();

      if (!$rootScope.checkIfUserIs('admin')) {
        await CompanyService.getCurrentCompanyPromise().$promise;
        companyId = $rootScope.companyId;

        await self.changeDate(self.rangePickerApi.getEvalDate());
      }
    });

    function createPagination() {
      return count({
        companyId: companyId,
        from: dateFrom,
        to: dateTo,
        statuses: statusesToFilter
      }).then(function (totalRecords) {
        self.totalItems = totalRecords.count;
      });
    }

    /**
     * Get records filter by provided options
     * @param {Object} options Data options
     * @returns
     */
    function getRecordsWhereFilter(options) {
      let andFilter = [];
      if (options.from && options.to) {
        andFilter = andFilter
          .concat({ date: { gt: options.from } })
          .concat({ date: { lt: options.to } });
      }

      if (options.companyId) {
        andFilter = andFilter.concat({ companyId: companyId });
      }

      if (options.statuses && options.statuses.length) {
        andFilter = andFilter.concat({ status: { inq: options.statuses } });
      }

      return {
        and: andFilter
      };
    }

    function getRecords(options) {
      const limit = options.limit || 0;
      const offset = options.offset || 0;
      const order = options.order || 'date DESC';

      return SdsRequest.find({
        filter: {
          where: getRecordsWhereFilter(options),
          limit: limit,
          offset: offset,
          order: order,
          include: {
            relation: 'history',
            scope: { fields: ['id', 'note'] }
          }
        }
      }).$promise;
    }

    function getOffset() {
      return getOffsetHelper(self.startFrom, self.PER_PAGE);
    }

    function count(options) {
      return SdsRequest.count({ where: getRecordsWhereFilter(options) }).$promise;
    }
  }
]);
