import app from '../../app';

app.directive('sdsFormBuilder', [
  '$translate',
  function () {
    return {
      restrict: 'E',
      templateUrl: require('./sdsFormBuilder.html'),
      scope: {
        formConfiguration: '=',
        formModel: '=',
        autoApproveSdsRequest: '=',
        uploader: '=',
        selectFile: '=',
        getFiles: '=',
        removeFile: '=',
        fileChanged: '=',
        requesterFieldChange: '&onRequesterFieldChange',
        formFieldChange: '&onFormFieldChange'
      },
      link: function (scope) {
        scope.formConfiguration.forEach(field => {
          //init model values
          if (field.type === 'plaintext') {
            scope.formModel[field.name] = 'SPECIAL_FIELD_PLAIN_TEXT';
          } else {
            scope.formModel[field.name] = '';
          }
        });
        scope.multiselect = {
          tagsWorkflowModel: null,
          inventoryWorkflowModel: null
        };
        scope.getSelectValues = values => values.map(el => el.value || el);
        scope.onFieldChange = fieldData => {
          if (fieldData.tagsWorkflow && scope.formFieldChange) {
            scope.formFieldChange({
              field: 'tagsWorkflowModel',
              value: scope.multiselect.tagsWorkflowModel
            });
          }
          if (fieldData.inventoryWorkflow && scope.formFieldChange) {
            scope.formFieldChange({
              field: 'inventoryWorkflowModel',
              value: scope.multiselect.inventoryWorkflowModel
            });
          }
          if (
            (fieldData.inventoryWorkflow &&
              fieldData.requesterFormInventories &&
              fieldData.requesterFormInventories.length > 0) ||
            (fieldData.tagsWorkflow &&
              fieldData.requesterFormTags &&
              fieldData.requesterFormTags.length > 0) ||
            fieldData.requesterForm ||
            (fieldData.type === 'dropdown' && fieldData.values.some(el => el.requesterForm))
          ) {
            scope.requesterFieldChange({ fieldData });
          }
        };
      }
    };
  }
]);

